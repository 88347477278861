import React from 'react';
import MyLogo from '../../../../assets/logo.svg'

/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 */
export default function Logo() {
  return (
    <MyLogo style={{ width: 50, height: 50 }} />
  );
}